/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable react-hooks/exhaustive-deps */

/**
 * PS_AP_1
 * import the required pacakages
 */
import React, { useState, useEffect, useContext } from 'react';
import {
  RACTable,
  RACTableCell,
  RACTableRow,
  RACCheckBox,
  RACButton,
  Grid,
  Typography,
  Box,
} from '@rentacenter/racstrap';
import { agreementContext } from '../context/PaymentInformationContext';
import { useClasses } from '../stylesJS/handleAuopayStyle';
import { schedule, dateFormatWithoutDay } from '../utils/scheduleAndDateFormat';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { sendMessage } from '../../api/user';
import CONSTANTS from '../constants/constant';
import { Dispatch, SetStateAction } from 'react';
import {
  paymentMethod,
  ParamTypeForAutopay,
} from '../interface/handleAutopayInterface';
import { useHistory, useParams } from 'react-router-dom';

export interface Autopayprops {
  unEnrollAutopay: boolean;
  manageCard: boolean;
  racExchange: boolean;
  cardPopup: boolean;
  setCardPopup: Dispatch<SetStateAction<boolean>>;
}
export default function Autopay(props: Autopayprops) {
  const classes = useClasses();

  /**
   * PS_AP_2
   * @construct variables
   * PS_AP_14
   */
  const agreementInfo = useContext(agreementContext);
  const history = useHistory();
  const { agreementId } = useParams<ParamTypeForAutopay>();
  const urlParams = new URLSearchParams(window.location.search);
  const exchangeAgreementId = urlParams.get(
    CONSTANTS.URLPARAMS_EXCHANGE_AGREEMENT_ID
  );

  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const [selectedObjects, setSelectedObjects] = useState<AgreementDetails[]>(
    []
  );
  const [currentSchedule, setCurrentSchedule] = useState<string>();
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(true);
  const [customerApproval, setCustomerApproval] = useState<boolean>(false);
  const sendAutopaySignupText: string = CONSTANTS.SEND_AUTOPAY_SIGNUP_TEXT;
  const sendText = CONSTANTS.SEND_TEXT;
  const number =
    agreementInfo.customerInfo.customerDetails[CONSTANTS.ZERO_NUMBER]
      .phoneNumber;
  const firstName =
    agreementInfo.customerInfo.customerDetails[CONSTANTS.ZERO_NUMBER].firstName;
  const lastName =
    agreementInfo.customerInfo.customerDetails[CONSTANTS.ZERO_NUMBER].lastName;
  const customerId = agreementInfo.customerInfo.customerId;
  const emailAddress =
    agreementInfo.customerInfo.customerDetails[CONSTANTS.ZERO_NUMBER]
      .emailAddress;

  /**
   * PS_AP_15
   * This useEffect is used to update the variable to get the
   * initial checked agreements
   * PS_AP_22
   */
  useEffect(() => {
    // Initialize checked items for agreements and clubs
    if (!props.manageCard) {
      const initialAgreementCheckedItems: boolean[] =
        agreementInfo.agreementDetails.map(
          (agreement: AgreementDetails) => agreement.selected
        );

      // Set agreements and clubs data
      setCheckedItems(initialAgreementCheckedItems);

      const selectedAgreementObjects: AgreementDetails[] =
        agreementInfo.agreementDetails.filter(
          (agreement: AgreementDetails) => agreement.selected
        );

      setSelectedObjects(selectedAgreementObjects);

      selectedAgreementObjects[CONSTANTS.ZERO_NUMBER]?.agreementSchedule !==
      undefined
        ? setCurrentSchedule(
            selectedAgreementObjects[CONSTANTS.ZERO_NUMBER].agreementSchedule
          )
        : setCurrentSchedule(CONSTANTS.EMPTY_STRING);
    } else {
      // Filter selected agreement objects
      const selectedAgreementObjects: AgreementDetails[] =
        agreementInfo.agreementDetails.filter(
          (agreement: AgreementDetails) => agreement.selected
        );

      setSelectedObjects(selectedAgreementObjects);

      setCurrentSchedule(CONSTANTS.EMPTY_STRING);
    }
  }, []);

  /**
   * PS_AP_50
   * @param index
   * This function handles on change of the checkbox on agreement grid
   * PS_AP_58
   */
  const handleCheckboxChange = (index: number) => {
    const updatedItemsAgr = [...checkedItems];

    updatedItemsAgr[index] = !updatedItemsAgr[index];
    setCheckedItems(updatedItemsAgr);

    if (updatedItemsAgr.every((item: boolean) => !item)) {
      setCurrentSchedule(CONSTANTS.EMPTY_STRING);
      setCustomerApproval(false);
      setSubmitButtonDisabled(true);
    }
  };

  /**
   * PS_AP_82
   * @param rateDetails
   * @returns autoPayAmountWithTax
   * this function calculate the total amount of the respestice agreements and clubs
   * PS_AP_93
   */
  const calculateSchduleRates = (rateDetails: AgreementDetails[]) => {
    const autoPayAmountWithTax: number[] = [];
    for (let i = CONSTANTS.ZERO_NUMBER; i <= rateDetails.length - 1; i++) {
      const scheduleRefCode = rateDetails?.[i]?.originalSchedule?.includes(
        CONSTANTS.SEMI
      )
        ? CONSTANTS.SEMI_MONTHLY
        : rateDetails[i].originalSchedule === CONSTANTS.MON
        ? CONSTANTS.MONTHLY
        : rateDetails[i].originalSchedule === CONSTANTS.BIWK
        ? CONSTANTS.BI_WEEKLY
        : rateDetails[i].originalSchedule === CONSTANTS.WK
        ? CONSTANTS.WEEKLY
        : CONSTANTS.EMPTY_STRING;

      if (rateDetails[i].agreementType !== CONSTANTS.CLUB) {
        switch (scheduleRefCode) {
          case CONSTANTS.WEEKLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i]?.agreementRateDetails?.weeklyRent) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.weeklyRent)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i]?.agreementRateDetails?.weeklyRentTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i]?.agreementRateDetails?.weeklyRentTax)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.weeklyLdw) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.weeklyLdw)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.weeklyLdwTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.weeklyLdwTax)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.weeklyPolicy) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.weeklyPolicy)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.weeklyPolicyTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.weeklyPolicyTax)
                  : CONSTANTS.ZERO_NUMBER)
            );
            break;
          case CONSTANTS.BI_WEEKLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i].agreementRateDetails?.biWeeklyRent) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.biWeeklyRent)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.biWeeklyRentTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.biWeeklyRentTax)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.biWeeklyLdw) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.biWeeklyLdw)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.biWeeklyLdwTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.biWeeklyLdwTax)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.biWeeklyPolicy) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.biWeeklyPolicy)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(
                  rateDetails[i].agreementRateDetails?.biWeeklyPolicyTax
                ) > CONSTANTS.ZERO_NUMBER
                  ? Number(
                      rateDetails[i].agreementRateDetails?.biWeeklyPolicyTax
                    )
                  : CONSTANTS.ZERO_NUMBER)
            );
            break;
          case CONSTANTS.MONTHLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i].agreementRateDetails?.monthlyRent) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.monthlyRent)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.monthlyRentTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.monthlyRentTax)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.monthlyLdw) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.monthlyLdw)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.monthlyLdwTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.monthlyLdwTax)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.monthlyPolicy) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.monthlyPolicy)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.monthlyPolicyTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(
                      rateDetails[i].agreementRateDetails?.monthlyPolicyTax
                    )
                  : CONSTANTS.ZERO_NUMBER)
            );
            break;
          case CONSTANTS.SEMI_MONTHLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i].agreementRateDetails?.semiMonthlyRent) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.semiMonthlyRent)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(
                  rateDetails[i].agreementRateDetails?.semiMonthlyRentTax
                ) > CONSTANTS.ZERO_NUMBER
                  ? Number(
                      rateDetails[i].agreementRateDetails?.semiMonthlyRentTax
                    )
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.semiMonthlyLdw) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.semiMonthlyLdw)
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(
                  rateDetails[i].agreementRateDetails?.semiMonthlyLdwTax
                ) > CONSTANTS.ZERO_NUMBER
                  ? Number(
                      rateDetails[i].agreementRateDetails?.semiMonthlyLdwTax
                    )
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(
                  rateDetails[i].agreementRateDetails?.semiMonthlyPolicy
                ) > CONSTANTS.ZERO_NUMBER
                  ? Number(
                      rateDetails[i].agreementRateDetails?.semiMonthlyPolicy
                    )
                  : CONSTANTS.ZERO_NUMBER) +
                (Number(
                  rateDetails[i].agreementRateDetails?.semiMonthlyPolicyTax
                ) > CONSTANTS.ZERO_NUMBER
                  ? Number(
                      rateDetails[i].agreementRateDetails?.semiMonthlyPolicyTax
                    )
                  : CONSTANTS.ZERO_NUMBER)
            );
            break;
          default:
            autoPayAmountWithTax.push(CONSTANTS.ZERO_NUMBER);
        }
      }
      if (rateDetails[i].agreementType === CONSTANTS.CLUB) {
        switch (scheduleRefCode) {
          case CONSTANTS.WEEKLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i].agreementRateDetails?.weeklyClub) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.weeklyClub)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.weeklyClubTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.weeklyClubTax)
                  : CONSTANTS.ZERO_NUMBER)
            );

            break;
          case CONSTANTS.BI_WEEKLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i].agreementRateDetails?.biWeeklyClub) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.biWeeklyClub)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.biWeeklyClubTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.biWeeklyClubTax)
                  : CONSTANTS.ZERO_NUMBER)
            );
            break;
          case CONSTANTS.MONTHLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i].agreementRateDetails?.monthlyClub) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.monthlyClub)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(rateDetails[i].agreementRateDetails?.monthlyClubTax) >
                CONSTANTS.ZERO_NUMBER
                  ? Number(rateDetails[i].agreementRateDetails?.monthlyClubTax)
                  : CONSTANTS.ZERO_NUMBER)
            );
            break;
          case CONSTANTS.SEMI_MONTHLY:
            autoPayAmountWithTax.push(
              (Number(rateDetails[i].agreementRateDetails?.semiMonthlyClub) >
              CONSTANTS.ZERO_NUMBER
                ? Number(rateDetails[i].agreementRateDetails?.semiMonthlyClub)
                : CONSTANTS.ZERO_NUMBER) +
                (Number(
                  rateDetails[i].agreementRateDetails?.semiMonthlyClubTax
                ) > CONSTANTS.ZERO_NUMBER
                  ? Number(
                      rateDetails[i].agreementRateDetails?.semiMonthlyClubTax
                    )
                  : CONSTANTS.ZERO_NUMBER)
            );
            break;
          default:
            autoPayAmountWithTax.push(CONSTANTS.ZERO_NUMBER);
        }
      }
    }
    return autoPayAmountWithTax;
  };

  /**
   * PS_AP_61
   * This function handles the disablement of send Text / send autopay signup text button
   * PS_AP_66
   */
  const conformationChkboxAction = () => {
    if (customerApproval === true) {
      setCustomerApproval(false);
      setSubmitButtonDisabled(true);
    } else {
      setCustomerApproval(true);
      setSubmitButtonDisabled(false);
    }
  };

  /**
   * PS_AP_23
   * @returns the HTML part to render the agreement grid
   * PS_AP_37
   */
  const ListOfAgreementsBind = () => {
    // const agreements = props.unEnrollAutopay
    //   ? selectedObjects
    //   : agreementInfo.agreementDetails;

    const agreements = agreementInfo.agreementDetails;
    const storeNumber = sessionStorage.getItem('storeNumber');
    return (
      <>
        {agreements.map((value: AgreementDetails, index: number) => (
          <>
            {!props.manageCard && // unEnroll autopay
              !props.racExchange &&
              props.unEnrollAutopay &&
              value.storeNumber == storeNumber &&
              value.isAutopayEnroll !== null &&
              value.agreementType !== CONSTANTS.CREDIT_CARD_CHARGE_BACK &&
              value.agreementType !== CONSTANTS.NSF_CHECK &&
              value.agreementType !== CONSTANTS.PROMO &&
              !value.isExchanged &&
              (value.agreementStatus == CONSTANTS.ACTIVE ||
                value.agreementStatus == CONSTANTS.STATUS_ACTIVE ||
                value.agreementStatus == CONSTANTS.PIF) && (
                <RACTableRow key={index} className={classes.racTable}>
                  <RACTableCell className={classes.checkboxwidth}>
                    <RACCheckBox
                      checked={checkedItems[index]} // checkedItems for managing checked state
                      disabled={
                        currentSchedule != value.agreementSchedule &&
                        currentSchedule != CONSTANTS.EMPTY_STRING
                          ? true
                          : false
                      }
                      onChange={() => {
                        setCurrentSchedule(value.agreementSchedule);
                        handleCheckboxChange(index);
                      }}
                    />
                  </RACTableCell>
                  <RACTableCell>{value.agreementDesc}</RACTableCell>
                  <RACTableCell>
                    {calculateSchduleRates([value]).map((num: number) =>
                      num.toFixed(2)
                    )}
                  </RACTableCell>
                  <RACTableCell>
                    {schedule(value.originalSchedule as string)}
                  </RACTableCell>
                  <RACTableCell>
                    {dateFormatWithoutDay(value.currentDueDate)}
                  </RACTableCell>
                </RACTableRow>
              )}
            {((!props.manageCard &&
              !props.unEnrollAutopay &&
              value.isAutopayEnroll === null && //enroll autopay
              value.storeNumber == storeNumber &&
              value.agreementType !== CONSTANTS.CREDIT_CARD_CHARGE_BACK &&
              value.agreementType !== CONSTANTS.NSF_CHECK &&
              value.agreementType !== CONSTANTS.PROMO &&
              !value.isExchanged &&
              (value.agreementStatus == CONSTANTS.ACTIVE ||
                value.agreementStatus == CONSTANTS.STATUS_ACTIVE ||
                value.agreementStatus == CONSTANTS.PIF)) ||
              (props.racExchange && value.agreementId == agreementId)) && (
              <RACTableRow key={index} className={classes.racTable}>
                <RACTableCell className={classes.checkboxwidth}>
                  <RACCheckBox
                    checked={checkedItems[index]} // checkedItems for managing checked state
                    disabled={
                      currentSchedule != value.agreementSchedule &&
                      currentSchedule != CONSTANTS.EMPTY_STRING
                        ? true
                        : false
                    }
                    onChange={() => {
                      setCurrentSchedule(value.agreementSchedule);
                      handleCheckboxChange(index);
                    }}
                  />
                </RACTableCell>
                <RACTableCell>{value.agreementDesc}</RACTableCell>
                <RACTableCell>
                  {calculateSchduleRates([value]).map((num: number) =>
                    num.toFixed(2)
                  )}
                </RACTableCell>
                <RACTableCell>
                  {schedule(value.originalSchedule as string)}
                </RACTableCell>
                <RACTableCell>
                  {dateFormatWithoutDay(value.currentDueDate)}
                </RACTableCell>
              </RACTableRow>
            )}
            {props.manageCard &&
              !props.racExchange && // manage card
              !props.unEnrollAutopay &&
              value.isAutopayEnroll !== null &&
              value.storeNumber == storeNumber &&
              value.agreementType !== CONSTANTS.CREDIT_CARD_CHARGE_BACK &&
              value.agreementType !== CONSTANTS.NSF_CHECK &&
              value.agreementType !== CONSTANTS.PROMO &&
              !value.isExchanged &&
              (value.agreementStatus == CONSTANTS.ACTIVE ||
                value.agreementStatus == CONSTANTS.STATUS_ACTIVE ||
                value.agreementStatus == CONSTANTS.PIF) && (
                <RACTableRow key={index} className={classes.racTable}>
                  <RACTableCell className={classes.checkboxwidth}>
                    <RACCheckBox
                      disabled={
                        currentSchedule != value.agreementSchedule &&
                        currentSchedule != CONSTANTS.EMPTY_STRING
                          ? true
                          : false
                      }
                      checked={checkedItems[index]}
                      onChange={() => {
                        setCurrentSchedule(value.originalSchedule);
                        handleCheckboxChange(index);
                      }}
                    />
                  </RACTableCell>
                  <RACTableCell>{value.agreementDesc}</RACTableCell>
                  <RACTableCell>
                    {calculateSchduleRates([value]).map((num: number) =>
                      num.toFixed(2)
                    )}
                  </RACTableCell>
                  <RACTableCell>
                    {schedule(value.originalSchedule as string)}
                  </RACTableCell>
                  <RACTableCell>
                    {dateFormatWithoutDay(value.currentDueDate)}
                  </RACTableCell>
                </RACTableRow>
              )}
          </>
        ))}
      </>
    );
  };

  /**
   * PS_AP_38
   * @returns the HTML part to render the customer cards grid
   * PS_AP_47
   */
  function bindCustomerCards() {
    const removeDuplicates = (
      arr: paymentMethod[],
      key: string
    ): paymentMethod[] => {
      return Array.from(new Map(arr.map((item) => [item[key], item])).values());
    };
    let filteredCardData: paymentMethod[] =
      agreementInfo.customerInfo.customerCardDetails.paymentMethods?.filter(
        (value) =>
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_PP &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_BL &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_EC &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_GC &&
          value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_VM &&
          (Number(agreementInfo.featureFlags?.EnableCashAppFeature) == 1
            ? true
            : value.cardTypeRef !== CONSTANTS.CARD_REF_TYPE_CASHAPP) &&
          value.cardTypeRef !== null &&
          value.cardTypeRef !== CONSTANTS.EMPTY_STRING
      );
    if (filteredCardData == undefined) {
      filteredCardData = [];
    } else {
      const uniqueArray: paymentMethod[] = removeDuplicates(
        filteredCardData,
        CONSTANTS.LASTFOUR
      );
      filteredCardData = uniqueArray;
    }
    return filteredCardData.map((value, index: number) => (
      <Grid key={index}>
        <Typography>
          <RACCheckBox
            role="checkbox"
            name={value.lastFour.toString()}
            checked={selectedCard === value.lastFour}
            onChange={() =>
              setSelectedCard(
                selectedCard === value.lastFour ? null : value.lastFour
              )
            }
          />
          <Box
            component="span"
            className={classes.mb1}
            style={{ marginLeft: '5px' }}
          >
            xxxx-xxxx-xxxx-{value.lastFour}
          </Box>
        </Typography>
      </Grid>
    ));
  }

  /**
   * PS_AP_116
   * the object autoPopUpHeadFields is used to render the header label
   * the function renderBindGridHead is used to render the headers of the agreement grid
   * PS_AP_118
   */
  const autoPopUpHeadFields = CONSTANTS.AUTO_PAY_POPUP_HEADER_FIELDS;

  const renderBindGridHead = () => (
    <>
      {autoPopUpHeadFields.map((val, index) => {
        return (
          <RACTableCell className={classes.racTable} key={index}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );

  /**
   * PS_AP_68
   * This function create the payload data to be sent on the final button click
   * PS_AP_108
   */
  const handleButtonClick = async () => {
    const selectedAgreementIds = props.unEnrollAutopay
      ? selectedObjects
          .filter((obj) => obj.selected)
          .map((obj) => obj.agreementId)
      : checkedItems
          .map((checked, index) =>
            checked ? agreementInfo.agreementDetails[index].agreementId : null
          )
          .filter((id) => id !== null);

    const currentClubId =
      agreementInfo.agreementDetails?.find(
        (agreement: AgreementDetails) =>
          agreement.agreementType === CONSTANTS.CLUB
      )?.agreementId ?? CONSTANTS.EMPTY_STRING;

    const clubId = selectedAgreementIds.find(
      (clubId) => clubId == currentClubId
    );

    const selectedAgreementIdsOnly = selectedAgreementIds.filter(
      (item) => item !== clubId
    );

    const selectedAgreementsForCalci: AgreementDetails[] = [];
    checkedItems.map((checked, index) => {
      if (checked) {
        const agreementDetail = agreementInfo.agreementDetails[index];
        if (agreementDetail) {
          selectedAgreementsForCalci.push(agreementDetail);
        }
      }
    });

    const calculatedSchduleRatesForAgr = calculateSchduleRates(
      selectedAgreementsForCalci
    );

    const amount = calculatedSchduleRatesForAgr.reduce(
      (acc, curr) => acc + curr,
      CONSTANTS.ZERO_NUMBER
    );

    let payload = {};
    const selectedCardDetails =
      agreementInfo?.customerInfo?.customerCardDetails?.paymentMethods?.filter(
        (card) => {
          return card.lastFour == selectedCard;
        }
      );
    const customerPaymentMethodId = selectedCardDetails?.[0]?.paymentMethodId
      ? selectedCardDetails?.[0]?.paymentMethodId
      : CONSTANTS.EMPTY_STRING;

    if (props.unEnrollAutopay) {
      payload = {
        sendTextFlag: 1,
        callTxtReq: {
          language: CONSTANTS.LANG_ENGLISH,
          phoneNumber: String(number),
          textMessage: CONSTANTS.TEXT_MESSAGE_UNENROLL,
          messageType: CONSTANTS.TEXT_MESSAGE_UNENROLL,
          messageTemplateKey: CONSTANTS.MESSAGE_TEMPLATE_KEY_UNENROLL,
          messageSubstitutes: {
            schedule:
              currentSchedule == CONSTANTS.SEMI
                ? CONSTANTS.SEMI_MONTHLY
                : currentSchedule === CONSTANTS.MON
                ? CONSTANTS.MONTHLY
                : currentSchedule === CONSTANTS.BIWK
                ? CONSTANTS.BI_WEEKLY
                : currentSchedule === CONSTANTS.WK
                ? CONSTANTS.WEEKLY
                : CONSTANTS.EMPTY_STRING,
            amount: String(Number(amount).toFixed(2)),
          },
          callBackParams: {
            lastName: String(lastName),
            firstName: String(firstName),
            customerId: customerId,
            customerPaymentMethodId: customerPaymentMethodId,
            agreementIds: selectedAgreementIdsOnly,
            clubId: clubId ? clubId : CONSTANTS.EMPTY_STRING,
          },
          storeNumber: String(
            window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
          ),
          transactionId: customerId,
        },
      };
    } else if (props.racExchange && selectedCard !== null) {
      const exchangeAgreementDetails = agreementInfo.agreementDetails.filter(
        (agreement) => agreement.agreementId == agreementId
      );
      payload = {
        sendTextFlag: 1,
        callTxtReq: {
          language: CONSTANTS.LANG_ENGLISH,
          phoneNumber: String(number),
          textMessage: CONSTANTS.TEXT_MESSAGE_ENROLL,
          messageType: CONSTANTS.TEXT_MESSAGE_ENROLL,
          messageTemplateKey: CONSTANTS.MESSAGE_TEMPLATE_KEY_ENROLL,
          messageSubstitutes: {
            schedule:
              exchangeAgreementDetails[0].agreementSchedule == CONSTANTS.SEMI
                ? CONSTANTS.SEMI_MONTHLY
                : exchangeAgreementDetails[0].agreementSchedule ===
                  CONSTANTS.MON
                ? CONSTANTS.MONTHLY
                : exchangeAgreementDetails[0].agreementSchedule ===
                  CONSTANTS.BIWK
                ? CONSTANTS.BI_WEEKLY
                : exchangeAgreementDetails[0].agreementSchedule === CONSTANTS.WK
                ? CONSTANTS.WEEKLY
                : CONSTANTS.EMPTY_STRING,
            amount: calculateSchduleRates(exchangeAgreementDetails).map(
              (num: number) => num.toFixed(2)
            ),
            lastFour: selectedCard,
          },
          callBackParams: {
            lastName: String(lastName),
            firstName: String(firstName),
            customerId: customerId,
            customerPaymentMethodId: customerPaymentMethodId,
            agreementIds: agreementId,
            clubId: clubId ? clubId : CONSTANTS.EMPTY_STRING,
          },
          storeNumber: String(
            window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
          ),
          transactionId: customerId,
        },
      };
    } else if (selectedCard !== null) {
      payload = {
        sendTextFlag: 1,
        callTxtReq: {
          language: CONSTANTS.LANG_ENGLISH,
          phoneNumber: String(number),
          textMessage: CONSTANTS.TEXT_MESSAGE_ENROLL,
          messageType: CONSTANTS.TEXT_MESSAGE_ENROLL,
          messageTemplateKey: CONSTANTS.MESSAGE_TEMPLATE_KEY_ENROLL,
          messageSubstitutes: {
            schedule:
              currentSchedule == CONSTANTS.SEMI
                ? CONSTANTS.SEMI_MONTHLY
                : currentSchedule === CONSTANTS.MON
                ? CONSTANTS.MONTHLY
                : currentSchedule === CONSTANTS.BIWK
                ? CONSTANTS.BI_WEEKLY
                : currentSchedule === CONSTANTS.WK
                ? CONSTANTS.WEEKLY
                : CONSTANTS.EMPTY_STRING,
            amount: String(Number(amount).toFixed(2)),
            lastFour: selectedCard,
          },
          callBackParams: {
            lastName: String(lastName),
            firstName: String(firstName),
            customerId: customerId,
            customerPaymentMethodId: customerPaymentMethodId,
            agreementIds: selectedAgreementIdsOnly,
            clubId: clubId ? clubId : CONSTANTS.EMPTY_STRING,
          },
          storeNumber: String(
            window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
          ),
          transactionId: customerId,
        },
      };
    } else if (selectedCard == null) {
      payload = {
        sendTextFlag: CONSTANTS.ZERO_NUMBER,
        signupReq: {
          phoneNumber: String(number),
          storeNumber: String(
            window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
          ),
          partyId: customerId,
          emailAddress: emailAddress,
        },
      };
    }

    sendMessage(payload);
    if (props.racExchange) {
      history.push(
        `/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`
      );
    }
    props.setCardPopup(false);
  };

  /**
   * PS_AP_110
   * This function handles the change of the button
   * PS_AP_113
   */
  function buttonText() {
    if (selectedCard !== null || props.unEnrollAutopay || props.manageCard) {
      return sendText;
    } else {
      return sendAutopaySignupText;
    }
  }

  /**
   * @returns HTMl part to render the autopaypopup
   */

  return (
    <>
      <div key={Math.random()}>
        <Grid item className={classes.w100}>
          <Grid item className={classes.justifycontentcenter}>
            <Grid item className={classes.raccollg7}>
              <Typography
                variant="h4"
                className={`${classes.bold} ${classes.mb3} ${classes.floatLeft} ${classes.textcenter} ${classes.mb3}`}
              >
                Discuss the benefits of Autopay and enroll your customer today
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid item className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  1
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Convenience
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Save Customer Time
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  2
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Zero Fees
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Avoid convenience/phone fees{' '}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Typography className={`${classes.font14} ${classes.mb3}`}>
            Please select agreement(s)/Club to enroll in Autopay. Items must be
            on the same schedule and same Due Date. Autopay will be effective on
            the next Due Date.
          </Typography>
          <Grid item className={`${classes.raccollg12} ${classes.floatLeft}`}>
            <Grid
              item
              className={`${classes.w100} ${classes.mb3} ${classes.floatLeft}`}
            >
              <RACTable
                renderTableHead={renderBindGridHead}
                renderTableContent={() => ListOfAgreementsBind()}
              />
            </Grid>
            <Grid item>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Grid
                  item
                  className={`${classes.w100} ${classes.font14} ${classes.mb3}`}
                >
                  {/* eslint-disable react/no-unescaped-entities */}
                  To enroll with a different schedule, use the 'Autopay Signup
                  Text' button on the Accept Payment screen - 'Signup with Card
                  on File' option.
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className={`${classes.justifycontentcenter}`}
              style={{ width: '100%' }}
            >
              {!props.unEnrollAutopay && (
                <Grid item className={classes.raccollg5}>
                  <Grid
                    item
                    className={`${classes.w100} ${classes.floatLeft} ${classes.textcenter} ${classes.mb2}`}
                  >
                    <Box component="label" className={classes.mb1}>
                      Please select one Card on File
                    </Box>
                  </Grid>
                  <Grid
                    item
                    className={`${classes.w100} ${classes.mb3} ${classes.textcenter}`}
                  >
                    {bindCustomerCards()}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {!props.unEnrollAutopay && (
              <Typography
                className={`${classes.w100} ${classes.textCenter} ${classes.font14} ${classes.mb3} ${classes.mandatoryfield}`}
              >
                Note:Only the last text message sent will be used for Autopay
                signup
              </Typography>
            )}

            {props.unEnrollAutopay && (
              <Typography
                className={`${classes.w100} ${classes.textCenter} ${classes.font14} ${classes.mb3} ${classes.mandatoryfield}`}
              >
                Note:Only the last text message sent will be used for Autopay
                un-enrollment
              </Typography>
            )}
          </Grid>
          <Grid
            item
            className={`${classes.formcheck} ${classes.textcenter} ${classes.ps5} ${classes.mb3}`}
          >
            <RACCheckBox
              role="checkbox"
              id="flexCheckDefault"
              onClick={() => conformationChkboxAction()}
              checked={customerApproval}
              disabled={
                currentSchedule == CONSTANTS.EMPTY_STRING ? true : false
              }
              data-testid="AutopayCheckTest"
            />
            <Box component="label">
              Customer has verbally consented to receive this text message
            </Box>
          </Grid>
          <Typography
            className={`${classes.font14} ${classes.mb3} ${classes.textcenter}`}
          >
            Autopay will be associated with the Primary Renter
          </Typography>
          <Grid
            item
            className={`${classes.justifycontentcenter} ${classes.mb3}`}
          >
            <Grid item className={classes.raccollg7}>
              <Grid
                item
                className={`${classes.w100} ${classes.w100} ${classes.mb2}`}
              >
                <Box
                  component="span"
                  className={`${classes.me3} ${classes.font14}`}
                >
                  {' '}
                  Customer Name
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {' '}
                  {firstName} {lastName}
                </Box>
              </Grid>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Box
                  component="span"
                  className={`${classes.w100} ${classes.me3} ${classes.font14}`}
                >
                  Phone Number
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {' '}
                  {number}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.raccollg12} ${classes.py4} ${classes.textcenter}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="outlined"
            data-bs-dismiss="modal"
            onClick={() => {
              if (props.racExchange) {
                history.push(
                  `/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`
                );
              }
              props.setCardPopup(false);
            }}
            data-testid="AutoPayNotnowTest"
          >
            Not now
          </RACButton>

          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#Customer-Signature"
            onClick={() => handleButtonClick()}
            disabled={submitButtonDisabled}
            data-testid="SendTxtAsPaymentTest"
          >
            {buttonText()}
          </RACButton>
        </Grid>
      </div>
    </>
  );
}
